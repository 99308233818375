export const groups = [
  [
    "Barnbana 2,5 km",
    "För yngre som vill pröva på trail på natten men på en lite kortare bana",
    "Barn",
  ],
  [
    "Snabba gruppen 9 km",
    "Du springer mycket trail, har en bra lampa, och du springer riktigt fort. Vill du tävla väljer du den här gruppen!",
    "Snabb",
  ],
  ["Lagomgruppen 9 km", "Alla andra.", "Lagom"],
]
export const groupSize = 150

export const prices = {
  standard: [
    { cutoff: "2024-08-31", price: 350 },
    { cutoff: "2024-10-31", price: 400 },
    { cutoff: "2024-11-24", price: 450 },
  ],
  short: [{ cutoff: "2024-11-24", price: 175 }],
}
